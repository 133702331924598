import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../services/api/api.service';

@Component({
  selector: 'app-basetabpage',
  templateUrl: './basetab.page.html',
  styleUrls: ['./basetab.page.scss'],
})
export class BasetabPage implements OnInit {

  protected isLoggedIn;

  constructor(
      private apiService: ApiService
  ) {
    this.apiService.monitorLoginState(loggedIn => {
      this.isLoggedIn = loggedIn;
    });
  }

  ngOnInit() {
  }

}
