import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {StorageServiceProvider} from '../storage-service/storage-service';

@Injectable({
  providedIn: 'root'
})
export class GiftcardsService {

  private cardCatalogCache;

  constructor(
      private restangular: Restangular,
      private storageService: StorageServiceProvider,
  ) {
  }

  getCardCatalog(): Promise<any> {
    if (this.cardCatalogCache) {
      return Promise.resolve(this.cardCatalogCache);
    }
    return this.restangular.one('v3').one('banks').one('tango').one('catalogs').get().toPromise().then(catalog => {
      this.cardCatalogCache = catalog.brands;
      return catalog.brands;
    });
  }

  getCardById(id: string): Promise<any> {
    return this.getCardCatalog().then(cards => {
      return cards.find(card => card.brandKey === id);
    });
  }

  getCardItemById(id: string): any {
    let cardItem = null;
    if (this.cardCatalogCache) {
      this.cardCatalogCache.forEach(card => {
        const found = card.items.find(thisItem => thisItem.utid === id);
        if (found) {
          cardItem = found;
        }
      });
    }
    return cardItem;
  }

  getCardItemByUtid(id: string): any {
    let cardItem = null;
    if (this.cardCatalogCache) {
      this.cardCatalogCache.forEach(card => {
        const found = card.items.find(thisItem => thisItem.utid === id);
        if (found) {
          cardItem = card;
        }
      });
    }
    return cardItem;
  }

  createOrder(familyId: string, userId: string, cardId: string, amount: number, email: string, name: string): Promise<any> {
    const data = {
      amount: amount,
      itemIdentifier: cardId,
      email: email,
      name: name
    };
    return this.restangular.one('v3').one('families', familyId).
     one('users', userId).one('banks').one('tango').one('gift-cards').customPOST(data).toPromise();
  }

  /**
   * MM-1222
   * ENDPOINT: POST /v4/families/:familyId/users/:userId/banks/tango/gift-cards
   *
   * @param familyId
   * @param userId
   * @param cardId
   * @param amount
   * @param email
   * @param name
   *
   * RESPONSE: 201
      { transactionId: 'ach_11em3v72g2dgk',
        tangoOrder:
        { referenceOrderID: 'RA190731-13327-79',
          customerIdentifier: 'acc03b60-35db-11e9-8f65-a95e85e321fa',
          accountIdentifier: 'ad2c1c90-35db-11e9-8f65-a95e85e321fa',
          accountNumber: 'A06677644',
          amountCharged: { currencyCode: 'USD', total: 0.01, value: 0.01 },
          denomination: { currencyCode: 'USD', value: 0.01 },
          utid: 'U666425',
          rewardName: 'Amazon.com Gift Card',
          sender:
            { email: 'info@wallit.app',
              firstName: 'info@wallit.app',
              lastName: '' },
          recipient:
            { email: 'marcos+radius004@ourly.com',
              firstName: 'parent',
              lastName: 'parent' },
          sendEmail: true,
          etid: 'E000000',
          status: 'COMPLETE',
          createdAt: '2019-07-31T19:32:43Z',
          reward:
            { credentials: [Object],
              credentialList: [Array],
              redemptionInstructions:
              '<p>|&nbsp; &nbsp;
              <a href="https://www.amazon.com/gp/css/gc/payment/view-gc-balance?claimCode=">
              Apply to Account </a>&nbsp; |&nbsp; <a href="http://www.amazon.com/gc-redeem">
              How to Use</a>&nbsp; |</p>\r\n\r\n<p>To redeem your gift card visit&nbsp;
              <a href="http://www.amazon.com/redeem">www.amazon.com/redeem.</a></p>\r\n\r\n<p>
              If you have questions about redeeming your gift card, please visit&nbsp;
              <a href="http://www.amazon.com/gc-redeem">www.amazon.com/gc-redeem.</a></p>\r\n' },
     marginShare: null }
   */
  createOrderRadius(familyId: string, userId: string, cardId: string, amount: number, email: string, name: string): Promise<any> {
    const data = {
      amount: amount,
      itemIdentifier: cardId,
      email: email,
      name: name
    };
    return this.restangular.one('v4').one('families', familyId).
     one('users', userId).one('banks').one('tango').one('gift-cards').customPOST(data).toPromise();
  }

  getOrderHistory(familyId: string, userId: string): Promise<any> {
    return this.restangular.one('v3').one('families', familyId).
    one('users', userId).one('banks').one('tango').one('orders').getList().toPromise();
  }

}
